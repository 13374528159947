<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">阅卷列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:</span>
              <el-input v-model="searchForm.examinationName" clearable size="small" placeholder="请输入考试名称"></el-input>
            </div>
            <div title="职业名称" class="searchboxItem ci-full">
              <span class="itemLabel">职业名称:</span>
              <el-select v-model="searchForm.occupationId" filterable remote clearable size="small" :remote-method="getOccupation" placeholder="请至少输入两个字搜索">
                <el-option v-for="item in occupation" :key="item.occupationId" :label="item.occupationName" :value="item.occupationId">
                </el-option>
              </el-select>
            </div>
            <div title="考试科目" class="searchboxItem ci-full">
              <span class="itemLabel">考试科目:</span>
              <el-select v-model="searchForm.subjectCode" placeholder="请选择考试科目" size="small" clearable>
                <el-option v-for="item in subjectCodeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="考试名称" align="center" show-overflow-tooltip prop="examinationName" minWidth="180" />
              <el-table-column label="职业名称" align="center" show-overflow-tooltip prop="occupationName" />
              <el-table-column label="等级" align="center" show-overflow-tooltip prop="levelCode">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试科目" align="center" show-overflow-tooltip prop="subjectCode">
                <template slot-scope="scope">
                  {{ $setDictionary("EA_EXAM_SUBJECT_CODE", scope.row.subjectCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试时间" align="center" show-overflow-tooltip minWidth="180">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} 至 {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="需阅卷人数" align="center" show-overflow-tooltip prop="peopleNum" minWidth="80" />
              <el-table-column label="已阅卷人数" align="center" show-overflow-tooltip prop="finishedNum" minWidth="80" /> 
              <el-table-column label="未阅卷人数" align="center" show-overflow-tooltip prop="noFinishedNum" minWidth="80" />
              <el-table-column label="操作" align="center" show-overflow-tooltip minWidth="180">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" :disabled="scope.row.noFinishedNum===0||moreTime(scope.row.endTime)" size="mini" @click="goScore(scope.row.examinationId)">去阅卷</el-button>
                  <el-button type="text" style="padding:0px 5px" :disabled="!scope.row.scoringShowUser" size="mini" @click="examineStudent(scope.row.examinationId)">查看学员</el-button>
                  <!-- <el-button type="text" style="padding:0px 5px" size="mini" @click="markFinishedBatch(scope.row.examinationId)">阅卷完成</el-button> -->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "examination_reviewPaperList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data () {
    return {
      searchForm: {
        examinationName: '',
        occupationId: '',
        subjectCode: '',
      },
      // 职业名称 - 下拉数据
      occupation: [],
      // 考试科目 - 下拉数据
      subjectCodeList: [],
    };
  },
  created () {
    this.getDictionary()
  },
  mounted () {
  },
  methods: {
    // 获取 - 职业名称
    getOccupation (occupationName) {
      if (occupationName.trim().length >= 2) {
        this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
          .then((ret) => {
            this.occupation = ret.data
          })
          .catch((err) => {
            return;
          });
      } else {
        this.occupation = [];
      }
    },
    //码值获取
    getDictionary () {
      // 考试科目
      const subjectCodeList = this.$setDictionary(
        "EA_EXAM_SUBJECT_CODE",
        "list"
      );
      const list1 = [];
      for (const key in subjectCodeList) {
        list1.push({
          value: key,
          label: subjectCodeList[key],
        });
      }
      this.subjectCodeList = list1;
    },
    getData (pageNum = 1) {
      const { examinationName, occupationId, subjectCode } = this.searchForm
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examinationName: examinationName || undefined,
        occupationId: occupationId || undefined,
        subjectCode: subjectCode || undefined,
      };
      this.doFetch({
        url: "/biz/examination/scoring/pageList",
        params,
        pageNum
      }, true, 6);
    },
    // 查看学员
    examineStudent (examinationId) {
      this.$router.push({
        path: "/web/examination/examineStudent",
        query: {
          examinationId,
        }
      });
    },
    // 去阅卷
    goScore (examinationId) {
      this.$router.push({
        path: "/web/examination/reviewPaperDetail",
        query: {
          examinationId,
          markType: '01'
        }
      });
    },
    // 阅卷完成
    markFinishedBatch (examinationId) {
      this.$post("/biz/examination/scoring/markFinishedBatch", { examinationId }, 3000, true, 6)
        .then((res) => {
          if (res.status == '0') {
            this.$message.success(res.message)
            this.getData()
          }
        })
        .catch((err) => {
          return;
        });
    },
    moreTime(endTime){
      const time = new Date(endTime).getTime()
      const newTime = new Date().getTime()
      return time>newTime
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {

  }
};
</script>
<style lang="less" scoped>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: '\e6df' !important;
  }
}
</style>
